// Max Width 768px
@media screen and (max-width: 768px) {
    .text-black-lg {
      font-size: 30px;
      line-height: 27px;
    }
    .text-white-lg,
    .text-lg {
      font-size: 25px;
      line-height: 22px;
    }
    .showroom-header{
      width:100%;
      min-width: auto;
      align-items: flex-start;
      padding:16px;
    }
    .showroom-booking-bg{
      background-color: #fff;
    }
    .vh-sm-auto {
      height: auto !important;
    }
    .plp-header {
      ::-webkit-scrollbar {
        display: none !important;
      }

      .bike-categories {
        margin-bottom: 0;
      }

      .bikes-tabs {
        width: auto;
      }
    }
    .brand-world-hero{
      margin-top: 70px;
    }

    .brand-world-categories{
        min-height: 466px;
       max-width: 208px;
    }

    .with-and-without-brand{
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .mainCircle,
    .secondaryCircle,
    .thirdCircle {
      top: 60px;
    }
    .background-mainCircle,
.background-secondaryCircle,
.background-thirdCircle {
  top:-24% ;
	left:-20%;
}
.typography-amount{
  font-size: 40px !important;
    line-height: 48px !important;
}
.brandworld-service-center-box{
  padding: 40px 0px 40px 0px;
  margin-top: 130px;
}
.fact-box{
  min-height:  288px;
  width: 288px;
}
    .top-searches{
      overflow-x: auto;
      white-space: nowrap;
      width: 100%;
      display: flex;
      padding: 0 10px 10px 0;
    }

    .faq-pic {
        display: none;
      }

      .faq-item {
        padding: 12px 0 16px 12px;
        background-color: #fff;
      }

      .faq-item span h3 {
        max-width: 90%;
        line-height: 24px;
        font-size: 16px;
        font-weight: 700;
      }

      .faq-item span p {
        font-size: 14px;
        line-height: 120%;
      }

      .mobile-navbar {
        transition: transform 0.3s ease, opacity 0.3s ease;
      }

      .mobile-navbar.hidden {

        transform: translateY(-100%);
        opacity: 0;
      }

      .mobile-navbar.sticky-top {
        position: sticky;
        top: 0;
      }

      .detail-wrap h2 {
        font-size: 14px;
        line-height: 18px;
        text-align: left;
      }

      .detail-wrap p {
        font-size: 14px;
        line-height: 18px;
        text-align: left;
      }
      .detail-img-wrap {
        background-color: white;
        padding: 1rem;
        border-radius: 100%;
      }
      .how-wrap h2 {
        text-align: left;
      }

      .how-wrap p {
        text-align: left;
      }

      .bike-details-tab-panel {
        padding-bottom: 0.5rem !important;

        h2 b {
          font-size: 20px !important;
        }

        ul {
          li {
            margin-bottom: 8px;
          }
        }

        strong {
          font-size: 1rem;
        }
      }

      .text-md {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      .slick-list-rounded-left {
        .slick-list {
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;
        }
      }
      .brand-world-lead-magnets{
        min-height: fit-content;
      }
      .bike-details-size {
        border: none !important;

        &:hover {
          color: #fff !important;
          background-color: #212529 !important;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .container-md {
        padding: 0;
      }

      .banner-btn {
        border-radius: 20px !important;
      }

      .home-banner-slider {
        .rounded-circle {
          display: none;
        }
      }

      .bike-details-size {
        border: none !important;

        &:hover {
          background-color: #212529 !important;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .bike-details-slider {
        .custom-dots {
          margin-bottom: 20px !important;
        }

        .slick-dots {
          height: 0;
          ul {
            padding-left: 0;
            margin-top: 10px;

            li {
              width: 10px;
              height: 10px;

              button:before {
                color: #212529;
                opacity: 1;
              }
            }
            li.slick-active {
              button:before {
                color: #fec04d;
                opacity: 1;
              }
            }
          }
        }
      }

      .bikes-slider-wrapper {
        .slick-track {
          padding-top: 20px !important;
        }
      }

      .downpayment-input-wrapper{
        padding: 8px 12px;
      }

      .filters-dropdown-item{
        padding: 6px 12px;
        border-radius: 4px;
        width: 100%;
        &:hover{
          background-color:  #EFF1F3;
        }

        &:active{
          background-color: #DCE1E7;
        }
      }

      .find-perfect-bike{
        .info-card{
          padding: 16px;
          min-height: 331px;

          .info-card-image{
            width: 100%;
            height: 155px;

            .info-card-title{
              left: 16px;
              bottom: 16px;
              line-height: 28px;
              font-size: 24px;
            }
          }
        }
      }

      .colors-sizes-wrapper, .duration-and-downpayment{
        border: none;
      }

      .duration-downpayment-wrapper, .sizes-colors-wrapper{
        border-bottom: 1px solid #dee2e6;
        border-radius: 0px !important;
      }

      .search-content{
        position: relative;
        height: calc(100vh - 330px);
        overflow-y: scroll;
        border-radius: 0px 0px 16px 16px;
      }

      .confirmation-section-title{
        font-size: 24px !important;
      }

      .miniPLP-container{
        .bikes-collection{
            display: grid;
            margin-top: 16px;
            justify-content: center;
            align-items: center;
            grid-template-columns: repeat(1, minmax(188px,1fr));

          }
      }

      .bikes-collection{
        display: grid;
        margin-top: 16px;
        justify-content: center;
        align-items: center;
        grid-template-columns: repeat(2, minmax(188px,1fr));

      }

      .similar-bikes-modal-content{
        padding: 0px ;
        justify-content: flex-start;
        align-items: center;
        grid-template-columns: repeat(2,minmax(170px,1fr)) !important;
      }

      .brand-info-section-container{
        margin-top: -33rem;
        border-top-left-radius: 0px;
  border-top-right-radius: 0px;
      }
      .miniPLP-container{
        padding: 0px 6px ;
        justify-content: center;
        align-items: center;
        grid-template-columns: repeat(2,minmax(170px,1fr)) !important;
      }

      .brand-info-section-container{
        margin-top: -33rem;
        border-top-left-radius: 0px;
  border-top-right-radius: 0px;
      }
      .miniPLP-container{
        padding: 0px 6px ;
        justify-content: center;
        align-items: center;
        grid-template-columns: repeat(2,minmax(170px,1fr)) !important;
      }
      .similar-bikes-container-padding{
        padding:40px 0px 0px;
      }

      .similar-bikes-child-container-padding{
        padding: 0px 0px;
      }

      .creditcheck-modal-content{
        height: 70vh;
      }

      .previous-filters-border{
        border-bottom: 1px solid #DEE2E6;
    }

    .brands-page-text{
        font-size: 18px !important;
        line-height: 26px !important;
    }

    .downpayment-price-tooltip{

        &::after {
            scale: 0.8;
            bottom: -16px;
        }
    }

    .grid-2{
        grid-template-columns: 1fr;
    }

    .bookmark{
        right:0px;
        top:25px;
    }

    .bookmark-list-view{
        width: 20px;
        height: 20px;
        right:8px;
        top:30px;
    }

    .wishlist-container {
        display: none;
    }


    .bike-explorer-tabs{
        width: "100%";
        overflow-x: scroll;
        white-space: nowrap;
    }
    .service-centers-filters{
        row-gap: 16px;
    }

    .bike-explorer-tabs-content{
        height: auto;

        .slick-slider .slick-list{
            max-height: 425px !important;
            height: 100%;
            padding-bottom: 10px;
        }
    }

    .leadmagnets-modal-content{
        padding: 24px 16px;
        height: 75vh;
    }

    .stories-section{
        background: transparent;
    }

    .sizes-colors-wrapper{
        border: none;
    }

    .whatsapp-slide{
        border-radius: 0px 0px 32px 32px;

        .backdrop{
        border-radius: 0px 0px 32px 32px;

        }
    }

    .fullscreen-slider-image{
        width: 100vw; 
    }

    .fullscreen-slider-close-btn{
        top: 50px;
    }

    .accordion-header span:first-child{
        font-size: 20px !important;
    }

    .brand-info-section-container{
        padding-bottom: 0px;
    }
}



// Max Width 1313px
@media screen and (max-width: 1313px) {
    .hero-section{
      margin-top:6rem;
    }
}


@media  screen and(max-width: 988px){
    .miniPLP-container {
        .bikes-collection{
            grid-template-columns: repeat(2,260px);
        }
    }
    .showroom-header{
     margin-bottom: 6rem;
     margin-top: 2rem;
     flex-direction: column;
     gap: 2rem;
    }
}


// Max Width 939px
@media screen and (max-width: 939px) {
    .hero-section{
      transform:translateY(0%);
      margin-top:-6rem;
    }
}

// Max Width 820px


// End of Max Width
// ==========================================================
// Start of Min Width

// Min Width 768px
@media screen and (min-width: 768px) {
    .label-absolute {
      margin-top: -1.8%;
    }
    .similar-bikes-modal-content{
      grid-template-columns: repeat(auto-fit, 190px);
  }
    #landing-page #cat-boxes h3 {
        font-size: 24px !important;
        line-height: 30px !important;
    }

    .home-banner-height{height:362px;}

    .bg-inherit-grey {
      background-color: #f8f8f8;
    }

    .bg-grey-inherit {
      background-color: inherit;
    }

    .bg-light-inherit {
      background-color: inherit;
    }

    .search-results {
      top: 65px;
      width: 50vw;
      margin-left: 50vw;
      max-height: 75vh;
      background-color: white;
      box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.25);
      border-radius: 0px 0px 3px 3px;
      position: absolute;
    }

    .book-appointment {
      overflow: hidden;
    }

    .newsletter-modal {
      max-width: 1020px;
    }

    .cart-compatibility-modal {
      max-width: 960px;
      max-height: 526px;
    }

    .cart-compatibility-modal-section {
      max-height: 260px;
    }

    .collection-grid-view-item {
      box-shadow: 0px 8px 24px 0px rgba(33, 37, 41, 0.12), 0px 8px 8px 0px rgba(33, 37, 41, 0.04);
    }

    .delivery-and-returns-sidebar-width {
      width: 50vw;
    }
    .banner-home-height {
      min-height: 70vh;
    }

    .delivery-and-returns-sidebar-width {
      width: 50vw;
    }

    .delivery-and-returns-sidebar-width {
      width: 50vw;
    }

    .delivery-and-returns-sidebar-height {
      min-height: 100vh;
    }

    .blog-post-book-consultation-sizes {
      min-width: 200px;
      min-height: 160px;
    }
    .comparison-plp-fixed-component {
      border: 1px;
      border-style: solid;
      border-color: #dee2e6;
    }

    .blog-post-book-consultation-sizes {
      min-width: 200px;
      min-height: 160px;
    }

    .blog-post-book-consultation-sizes {
      min-width: 200px;
      min-height: 160px;
    }

    .header-banner-title {
      top: 50%;
    }

    .header-banner-img {
      min-height: 292px;
    }

    .common-header {
      position: absolute;
    }

    .text-black-md-white {
      color: #fff;
    }

    .thumb-tooltip:after {
      bottom: -9px;
    }

    .bikes-tabs {
      width: auto;
    }

    .bike-size-btn:after {
      bottom: -8px;
    }

    .bikes-slider-wrapper {
      .slick-slider {
        max-height: 498px;
      }

      .slick-list {
        min-height: 615px ;
        padding-top: 10px !important;
      }

      .slick-dots {
        bottom: -60px !important;

        ul {
          margin-bottom: -4rem;

          li.slick-active button::before {
            color: #fec04d;
          }

          li:nth-child(n + 6):not(:last-child) {
            display: none;
          }
        }
      }

      .rounded-circle {
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        transform: translateY(-50%) translateX(110%) !important;
        transition: background-color 0.2s ease-in-out;

        &:hover {
          background-color: #eff1f3 !important;
        }

        &:active {
          background-color: #dce1e7 !important;
        }

        &:nth-child(1) {
          transform: translateY(-50%) translateX(-100%) !important;
        }
      }
    }

    .view-selector-dropdown-item {
      transition: background-color 0.2s ease-in-out;
      border-radius: 4px;
      &:hover {
        background-color: #eff1f3;
      }
    }

    .compatible-accessories {
      .slick-dots {
        ul {
          padding-left: 0;
          li {
            width: 8px;
            button:before {
              color: #212529;
              opacity: 1;
              width: 6px;
              height: 6px;
            }
          }
        }
      }
    }

    .go-back {
      &:hover {
        background-color: #eff1f3;
      }

      &:active {
        background-color: #dce1e7;
      }
    }

    .bike-details-tab-panel {
      h2 {
        font-size: 14px;
      }
      p {
        font-size: 16px;
      }
    }

    .sticky-wrapper-pdp {
      position: sticky;

      top: 20px;
      height: 100%;
    }

    .whatsapp-slide {
      border-radius: 16px;
    }

    .rounded-circle {
      height: 40px;
      width: 40px;
      border-radius: 4px !important;
      background: #f8f8f8;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .backdrop {
      border-radius: 16px;
    }

    .duration-downpayment-wrapper, .sizes-colors-wrapper{
      border: 1px solid #dee2e6;
    }

    .concept-wrapper{
        padding: 40px;
    }

    .wishlist-container-mobile {
        display: none;
    }

    .border-md-bottom{
        border-bottom: 1px solid #dee2e6 !important;
    }
}




@media (min-width: 768px) and (max-width: 1000px) {
  .background-mainCircle,
  .background-secondaryCircle,
  .background-thirdCircle {
    top:1% ;
    left:-50%;
  }
}

