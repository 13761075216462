.enter{
    // opacity:0.1;
    transform: translateX(-600px);
}
.enterActive{
    transition: all ease-out 0.4s;
    transform: translateX(0px);
    // opacity: 1;
    // background-color: aquamarine;
}
// .enterDone{
//     transition: all ease-out 0.2s;
//     opacity: 1;
//     transform: translateY(0px);
//     // background-color: black;
// }
.exit{
    // transition: all ease-out 0.4s;
    // opacity: 1;
    transform: translateX(0px);
    // background-color: black;
}
.exitActive{
    // opacity: 0.1;
    transition: all ease-in 0.4s;
    transform: translateX(-600px);
    // background-color: coral;
}
// .exitDone{
//     opacity:0;
//     transform: translateY(-1300px);
//     transition: all ease-out 0.2s;
//     // background-color: cornflowerblue;
// }