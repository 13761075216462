.scrollbar-hide {
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
	overflow-y: scroll;
}

.scrollbar-hide::-webkit-scrollbar {
	display: none; /* for Chrome, Safari, and Opera */
}

.center-absolute{
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%)
}

.slick-slider .rounded-circle{
    border-radius: 4px !important;
}