.enter{
    opacity:0.1;
}
.enterActive{
    transition: all ease-out 0.15s;
    opacity: 1;
}
// .enterDone{
//     transition: all ease-out 0.2s;
//     opacity: 1;
// }
.exit{
    opacity: 1;
}
.exitActive{
    opacity: 0;
    transition: all ease-out 0.15s;
}
// .exitDone{
//     transition: all ease-out 0.2s;
//     opacity: 0
// }