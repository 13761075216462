.heading1 {
  font-size: 3.5rem;
  line-height: 3.5rem;
  font-weight: 900;
  margin: 0;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.heading1Black {
  font-weight: 900;
  font-feature-settings: 'pnum' on, 'lnum' on;
  font-size: 1.75rem;
}

.heading1Bold {
  font-weight: 700;
  font-feature-settings: 'pnum' on, 'lnum' on;
  font-size: 1.75rem;
}

.heading2 {
  font-size: 2.25rem;
  line-height: 2.4rem;
  font-weight: 700;
  margin: 0;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.heading2Black{
  font-size: 2.25rem;
  line-height: 2.4rem;
  font-weight: 900;
  margin: 0;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.heading2Bold {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.4rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin: 0;
}

.heading3 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin: 0;
}

.heading3Black {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 2rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin: 0;
}

.heading3Sm {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 2rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin: 0;
}

.heading3SmBlack{
  font-weight: 900;
  font-size: 1.25rem;
  line-height: 2rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin: 0;
}

.heading4 {
  font-weight: 700;
  font-size: 1rem;
  line-height: 2rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin: 0;
}

.heading4Black {
  font-weight: 900;
  font-size: 1rem;
  line-height: 1.5rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin: 0;
}

.bodyLg {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.bodyLgBold {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.bodySm {
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
  line-height: 1.25rem;
}


.bodySmBold {
  font-weight: 700;
  font-size: 0.875rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
  line-height: 1.25rem;
}

.bodySmBlack {
  font-style: normal;
  font-weight: 900;
  font-size: 0.875rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
  line-height: 1.25rem;
}

.bodyXSm {
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
  line-height: 0.875rem;
}


.bodyXSmExtraBold {
  font-weight: 800;
  font-size: 0.75rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
  line-height: 1rem;
}


.heading1MdHeading2 {
  font-size: 2.25rem;
  line-height: 2.4rem;
  font-weight: 700;
  margin: 0;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.heading2MdLgBold {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  font-feature-settings: 'pnum' on, 'lnum' on;

}

.heading2MdHeading1 {
  font-size: 3.375rem;
  line-height: 3.5rem;
  font-weight: 700;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin: 0;
}

.heading2MdHeading3 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin: 0;
}

.bodySmMdBodyLg {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.bodySmBoldMdBodyLgBold {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.bodyXSmMdBodyLg {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.bodyXSmBoldMdBodyLgBold {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.heading3MdBodyLgBold {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.heading3MdBodyLgBlack {
  font-weight: 900;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.bodyXSmMdBodyLgBold {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.bodyXSmExtraBoldMdHeading3 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin: 0;
}



.bodyLgBlack {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.bodyXSmBold {
  font-weight: 700;
  font-size: 0.75rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
  line-height: 1rem;
}

.bodyXSmBoldMdBodySmBold {
  font-weight: 700;
  font-size: 0.875rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
  line-height: 1.25rem;
}

.heading28To36 {
  font-weight: 900;
  font-size: 36px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  line-height: 44px;
}

.heading20To28 {
  font-weight: 900;
  font-size: 28px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  line-height: 36px;
}

@media screen and (max-width: 767px) {
  .heading1 {
    font-style: normal;
    font-weight: 900;
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin: 0;
  }

  .heading1Light {
    font-weight: 700;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: 1.75rem;
  }

  .heading1Black {
    font-weight: 900;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: 1.75rem;
  }
  .heading2 {
    font-weight: 900;
    font-size: 1.75rem;
    line-height: 2rem;
    margin: 0;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }
  .heading3 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    margin: 0;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  .heading6 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    margin: 0;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  .bodyLg {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  .bodyLgBlack {
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  .bodyLgBold {
    font-weight: 700;
    font-size: 1rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    line-height: 1.5rem;
  }

  .bodyLgExtraBold {
    font-weight: 800;
    font-size: 1rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    line-height: 1.5rem;
  }

  .bodySm {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    line-height: 1.25rem;
  }

  .bodySmBold {
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    line-height: 1.25rem;
  }

  .bodySmBlack {
    font-style: normal;
    font-weight: 900;
    font-size: 0.875rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    line-height: 1.25rem;
  }

  .bodyXSm {
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    line-height: 0.875rem;
  }
  .bodyXSmBold {
    font-weight: 700;
    font-size: 0.75rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    line-height: 0.875rem;
  }

  .bodyXSmExtraBold {
    font-weight: 800;
    font-size: 0.75rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    line-height: 1rem;
  }

  .heading1MdHeading2 {
    font-style: normal;
    font-size: 1.75rem;
    line-height: 2.25rem;
    margin: 0;
    font-weight: 900;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }
  .heading2MdLgBold {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2rem;
    margin: 0;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }
  .heading2MdHeading1 {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2rem;
    margin: 0;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }
  .heading2MdHeading3 {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2rem;
    margin: 0;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  .bodySmMdBodyLg {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-feature-settings: 'pnum' on, 'lnum' on;

  }

  .bodySmBoldMdBodyLgBold {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 700;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  .bodyXSmMdBodyLg {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    line-height: 1.25rem;
  }

  .heading3Sm {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .bodyXSmBoldMdBodyLgBold {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  .heading3MdBodyLgBold {
    font-weight: 700;
    font-size: 0.875rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    line-height: 1.5rem;
  }

  .bodyXSmMdBodyLgBold {
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    line-height: 0.875rem;
  }

  .bodyXSmExtraBoldMdHeading3 {
    font-weight: 800;
    font-size: 0.75rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    line-height: 1rem;
    margin-bottom: 8px;
  }

  .bodyXSmBoldMdBodySmBold {
    font-weight: 700;
    font-size: 0.75rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    line-height: 0.875rem;
  }

  .heading28To36 {
    font-weight: 900;
    font-size: 28px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    line-height: 44px;
  }

  .heading20To28 {
    font-weight: 900;
    font-size: 20px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    line-height: 36px;
  }
}
