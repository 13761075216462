.infoBoxDottedLine {
  height: 17px;
  margin-left: 23px;
  background-image: linear-gradient(black 62%, rgba(255, 255, 255, 0) 0%);
  background-size: 2px 9px;
  background-repeat: repeat-y;
}

.infoBoxQuestionButton{
  background-color: #F8F8F8;
  border-radius: 16px !important;
  gap: 16px;
}

.infoBoxQuestionButton:hover {
  background-color: #DCE1E7;
}

.similarSizeButton:hover {
  background-color: #EFF1F3;
}

  .modalWrapper {
      min-width: 624px;
      border-radius: 32px;
      position:relative;    
      background-color: white;
      min-height: 400px;
      display:flex;
      flex-direction: column;
      justify-content:center;
      column-gap: 8px;
      padding: 24px 40px;
    }

  .contentWrapper {
      border-top-left-radius: 32px;
      border-bottom-left-radius: 32px;
  }

  .pictureWrapper {
      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;
  }
