.shake {
	animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 1000px;
}

@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}
	20%,
	80% {
		transform: translate3d(3px, 0, 0);
	}
	30%,
	50%,
	70% {
		transform: translate3d(-5px, 0, 0);
	}
	40%,
	60% {
		transform: translate3d(5px, 0, 0);
	}
}

.rotateAnimation{
	animation: rotateAnimation 2s infinite;
}

@keyframes rotateAnimation {
	0%, 100% {
	  transform: rotate(0deg);
	}
	20% {
	  transform: rotate(-20deg);
	}
	40% {
	  transform: rotate(20deg);
	}
	60% {
	  transform: rotate(0deg);
	}
	80% {
	  transform: rotate(-20deg);
	}
  }

  .slide-up-off-screen-17{
	animation: translateToTop-17 0.5s forwards;
  }

  @keyframes translateToTop-17 {
	from {
	  transform: translateY(0%);
	}
	to {
	  transform: translateY(-17%);
	}
  }

  .slide-down-from-top-17{
	animation: translateBack-17 0.5s forwards;

  }

  @keyframes translateBack-17 {
	from {
	  transform: translateY(-17%);
	}
	to {
	  transform: translateY(0%);
	}
  }



  .slide-up-off-screen{
	animation: translateToTop 0.5s forwards;
  }

  @keyframes translateToTop {
	from {
	  transform: translateY(0%);
	}
	to {
	  transform: translateY(-100%);
	}
  }

  .slide-down-from-top{
	animation: translateBack 0.5s forwards;

  }


  @keyframes translateBack {
	from {
	  transform: translateY(-100%);
	}
	to {
	  transform: translateY(0%);
	}
  }

  .suggestion-skeleton-loader{
	background: rgb(242,242,242);
background: linear-gradient(90deg, rgba(242,242,242,1) 36%, rgba(255,255,255,1) 53%, rgba(242,242,242,1) 63%);
	background-size: 300% 300%;
	animation: gradient-linear-advance 3s ease infinite;
  }


  @keyframes translateRightOutOfScreen {
	from {
	  transform: translateX(0%);
	}
	to {
	  transform: translateX(100%);
	}
  }

  @keyframes translateBackRightIntoScreen {
	from {
	  transform: translateX(100%);
	}
	to {
	  transform: translateX(0%);
	}
  }

  .toastHide{
	animation: translateRightOutOfScreen 0.6s linear;
	transform:translateX(100%)
  }

  .toastShow{
	animation: translateBackRightIntoScreen 0.6s linear;
  }


  .fade-in{
	animation: fade-in-animation 0.3s linear;
  }

  @keyframes fade-in-animation{
	from{
		opacity: 0.1;
	}
	to{
		opacity: 1;
	}
  }

  .fade-out {
	animation: fade-out-animation 0.3s linear forwards;
  }

  @keyframes fade-out-animation{
	from{
		opacity: 1;
	}
	to{
		opacity: 0;
		display: none;
	}
  }
  @keyframes floatUpDown {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-30px); 
	}
}

@keyframes floatUpDownMain {
	0%,
	100% {
		transform: translateY(-20px);
	}
	50% {
		transform: translateY(-60px); 
	}
}

@keyframes floatLeftRight {
	0%,
	100% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(-30px);
	}
}
  .slide-up {
    animation: slideUp 0.5s ease-in-out;
  }

  @keyframes slideUp {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slideLeftAnimation {
	0% {
	  transform: translateX(40%);
	}
	100% {
	  transform: translateX(0%);
	}
  }

  .video-container.slide-left {
    animation: slideLeftAnimation 0.5s ease-in-out forwards;
    // animation: transform 0.2s ease-in-out forwards;

  }

  .slide-in-right {
    animation: slideInRight 0.3s ease-in-out forwards;
}
  @keyframes slideInRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.slide-in-top {
	
    animation: slideInTop 0.3s ease-in-out forwards;
}
  @keyframes slideInTop {
    0% {
		opacity: 0;
        transform: translateY(-30%);
    }
    100% {
		opacity: 1;
        transform: translateY(0);
    }
}


@keyframes slideOutRight {
   from {
        transform: translateX(0);
    }
    to {
        transform: translateX(130%);
    }
}

@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

.slide-out-right {
    animation: slideOutRight 0.3s ease-in-out forwards;
}

.slide-in-left {
    animation: slideInLeft 0.3s ease-in-out forwards;
}


.rotate-right {
    transition: transform 0.3s ease;
    transform: rotateZ(1deg);
  }

  .rotate-left {
    transition: transform 0.3s ease;
    transform:  rotateZ(-1deg);
  }

  .slide-out-left {
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
}


// Slide enters from the right with opacity increasing
@keyframes slide-left {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slide-left-exit {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    50% {
      transform: translateX(-50%); 
      opacity: 0.5; 
    }
    100% {
      transform: translateX(-100%); 
      opacity: 0; 
    }
  }

  
  .slide-left-enter {
    animation: slide-left 1s ease forwards; 
  }
  
  .slide-left-exit {
    animation: slide-left-exit 2s ease forwards; 
  }

  @keyframes translateAnimation {
	0% {
	  transform: translateX(-40%) translateY(0%);
	}
	25% {
	  transform: translateX(30%) translateY(-5%);
	}
	50% {
	  transform: translateX(95%) translateY(0%);
	}
	75% {
	  transform: translateX(30%) translateY(-5%);
	}
	100% {
	  transform: translateX(-40%) translateY(0%);
	}
  }
  
  @keyframes translateAnimationBackwardsDirection {
	0% {
	  transform: translateX(95%) translateY(0%);
	}
	25% {
	  transform: translateX(30%) translateY(-5%);
	}
	50% {
	  transform: translateX(-40%) translateY(0%);
	}
	75% {
	  transform: translateX(30%) translateY(-5%);
	}
	100% {
	  transform: translateX(95%) translateY(0%);
	}
  }
  
  @keyframes gradient-change {
	0% {
		  background-position: 30% 50%;
	  }
	  50% {
		  background-position: 70% 50%;
	  }
	  100% {
		  background-position: 30% 50%;
	  }
  }

  @keyframes gradient-linear-advance {
	0% {
		  background-position: 0% 50%;
	  }
	  
	  100% {
		  background-position: 100% 50%;
	  }
  }