@media screen and (max-width: 768px) {

    .fixedWrapper{
        position: fixed;
        bottom: 0;
        left: 0;
    }

    .modalWrapper {
        width: 100vw;
        // min-width: 91vw;
      }

    .contentWrapper {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        margin-top: -25px;
    }
}


@media screen and (min-width: 768px) {
    .modalWrapper {
        max-width: 1020px;
        min-width: 1020px;
        border-radius: 32px;
      }

    .contentWrapper {
        border-top-left-radius: 32px;
        border-bottom-left-radius: 32px;
        border-top-right-radius: 32px;
        border-bottom-right-radius: 32px;
    }


}