.navDropdownItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 5px;
  margin-top: 5px;
  transition: background-color 0.2s ease-in-out;
}

.navDropdownItem a {
  display: block;
}

.navDropdownItem:hover {
  background-color: #EFF1F3;
}
.navDropdownItemActive {
  background-color: #EFF1F3;
}
.navDropdownItem a{
  display: block;
}

.megaMenuPictureContainer {
  background-image: url("../../public/assets/images/new-mega-menu-desk-pic.jpg");
  min-height: 510px;
  min-width: 510px;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
}

.megamenuItem {
  background-color: inherit;
  padding: 6px 16px;
}

.megamenuItem:hover {
  background-color: #EFF1F3;
}

.megamenuTopCategoryItem{
  background-color: inherit;
  padding: 12px 16px;
  max-width: 240px;
  width: 100%;
  column-gap: 48px;
  border-radius: 4px;

  &:hover{
    background: rgba(110, 144, 96, 0.20);
  }
}

@media screen and (min-width: 768px) {
  .megaMenuTopCategories{
    max-height: 70vh;
    overflow: hidden;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 768px){
  .megamenuItem {
    padding: 8px;
  }
}

.listIconBg {
  position:relative;
  padding: 8px;
  height:40px;
  width:40px;
  border-radius:4px;
  cursor:pointer;
  
  @media screen and (max-width: 767px) {
    margin:auto;
  }
}

.link_active{
    position: relative;
}

.link_active::after{
    content: "";
    position: absolute;
    bottom:0px;
    left: 0px;
    transition:all linear 0.3s;
    height: 4px;
    width: 100%;
    background-color: #FEC04D;
    border-radius: 4px 4px 0px 0px;
}

.emptyListNotification {
    padding: 16px 24px;
    border-radius: 16px;
    border: 1px solid #DEE2E6;
    background-color: #fff;
    position: absolute;
    top: 50px;
    right: 0px;
    width: 256px;
    z-index: 100000;
    opacity: 0;  
    visibility: hidden; 
    transition: opacity 0.3s ease, visibility 0.3s ease; 
}

.emptyListNotification.visible {
    opacity: 1;
    visibility: visible;  
}

.hoverEffect{
  transition:all linear 0.3s;
  background-color: white;
  &:hover{
    background-color: #EFF1F3;
  }
}