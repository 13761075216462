.hideVisually {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checkbox {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-right: 14px;
  border: 1px solid #D2D2DA;
  border-radius: 4px;

  &.checkboxActive {
    border: none;
    background-color: #000;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.checkbox_grey {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border: 2px solid #777777;
  border-radius: 3px;

  &.checkboxActive {
    border: none;
    background-color: #fec04d;
  }

  &.disabled {
    opacity: 0.5;
  }
}
