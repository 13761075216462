// Define custom variables
$primary: #fec04d;
$dark-blue: #090740;
$success: #23a455;
$light: #f3f2f2;
$dark: #000000;
$secondary: #777777;
$danger: red;
$green: #04724d;
$green-success: #2ca58d;
$red-danger: #fe4a49;
$darkTransparent: rgba(0, 0, 0, 0.2);

$theme-colors: (
  "primary": $primary,
  "success": $success,
  "light": $light,
  "dark": $dark,
  "dark-blue": $dark-blue,
  "secondary": $secondary,
  "danger": $danger,
  "green": $green,
  "green-success": $green-success,
  "red-danger": $red-danger,
);

$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
  "9x16": calc(16 / 9 * 100%),
);

// Required
@import "../node_modules/bootstrap/scss/functions";

// Required
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

$link-color: #fec04d !default;

// Optional Bootstrap components here
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";

//Fonts
// @import url("https://fonts.googleapis.com/css?family=Baloo 2");
// @font-face {
//   font-family: "Baloo 2";
//   src: local("Baloo 2"), url("https://fonts.googleapis.com/css?family=Baloo 2");
// }
