$arrow-size: 1.2rem;
$active-color: #ffc107;
$color: lightgrey;

.slick-dots {
	bottom: 25px;
	li {
		margin: 0;
		button {
			&::before {
				color: $color;
				font-size: 100px;
				opacity: 1;
			}
		}
		&.slick-active {
			color: #fec04d;
			button::before {
				color: $active-color;
				font-size: 12px;
				opacity: 1;
			}
		}
	}
}

.slick-list {
	height: auto !important;
}

.custom-dots {
	list-style: none;
	display: flex !important;
	justify-content: center;
	align-items: center;
	padding: 0;
	position: absolute;
	bottom: 0;
	width: 100%;
	li {
		margin: 0 2px;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: $color;
		button {
			border-radius: 50%;
			border: none;
			opacity: 0;
			width: 8px;
			height: 8px;
		}
		&.slick-active {
			background-color: $active-color;
			width: 10px;
			height: 10px;
		}
	}
}
