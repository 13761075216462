.slider-container {
    position: relative;
    overflow: hidden;
    max-width: 1235px;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
}

.slider-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
}

.slides {
    display: flex;
    width: 100%;
}

.slide {
    flex: 0 0 25%;
    max-width: 306px;
}

.prev-slide, .next-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    background: #fff;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    border: none;
    transition: 0.3s background-color ease;

    &:hover{
        background-color: #eff1f3;
    }
}

.prev-slide {
    left: -50px;
}

.next-slide {
    right: -50px;
}

.arrow-left-down,.arrow-right-down{
    top: unset;
    transform: unset;
}

.arrow-left-down{
    left: 0px;
}

.arrow-right-down{
    right: 0px;
}

.dots-container {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 60px;
    left: 50%;
    column-gap: 4px;
    z-index: 1000;
}

.dot {
    width: 8px;
    height: 4px;
    border-radius: 4px;
    background-color: #212529 !important;
    cursor: pointer;
    border: none;
}

.dot.active {
    width: 16px;
    background-color: #fec04d !important;
    transition:all .5s linear;
}