.enter{
    max-height: 0;
    // transition: all ease-out 0.5s;
}
.enterActive{
    transition: all ease-out 0.4s;
    max-height: 300px;
    // background-color: aquamarine;
}
// .enterDone{
//     transition: all ease-out 0.4s;
//     opacity: 1;
//     max-height: 1800px;
//     // background-color: black;
// }
.exit{
    opacity: 1;
    max-height: 300px;
    // background-color: black;
}
.exitActive{
    transition: all ease-out 0.4s;
    max-height: 0px;
    // background-color: coral;
}
// .exitDone{
//     transition: all ease-out 0.2s;
//     max-height: 0px;
//     // background-color: cornflowerblue;
// }